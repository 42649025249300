import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import slugify from 'slugify'

const close = (regionSlug, name) => {
  if (regionSlug === 'ext') {
    navigate('/')
  } else {
    navigate(`/${regionSlug}/directors/${slugify(name, { lower: true })}`)
  }
}

const FilmDetail = ({ data }) => {
  const film = data.contentfulFilm
  const regionSlug = data.contentfulRegion.slug
  return (
    <Layout region={data.contentfulRegion.slug} list={film.director.films} video={film} close={() => close(regionSlug, film.director.name)}>
      <SEO title={film.title} description={film.metaDescription?.metaDescription} />
    </Layout>
  )
}

export const query = graphql`
  query FilmDetail($regionId: String!, $filmId: String!) {
    contentfulRegion(id: { eq: $regionId }) {
      slug
    }
    contentfulFilm(id: { eq: $filmId }) {
      id
      title
      metaDescription {
        metaDescription
      }
      brand
      url
      director {
        name
        films {
          brand
          title
          url
          director {
            name
          }
        }
        region {
          slug
        }
      }
    }
  }
`

export default FilmDetail
